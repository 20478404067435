import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { NavLink, useNavigate } from 'react-router-dom';
import { useRoot } from '../../core/rootStore'
import iconCollapse from '../../assets/images/neo/collapse.svg';
import logoText from '../../assets/images/neo/logo-text.svg';
import logoImg from '../../assets/images/neo/logo-img.svg';
import { Search } from '../Search';
import { CountryItem } from '../list-items/CountryItem';
import { Dropdown } from '../Dropdown';
import { useUser } from '../../core';
import { LoginModal } from '../login-modal';
import { UserItem } from '../list-items/UserItem'
import { KovaaksPlusIcon } from '../icons/KovaaksPlusIcon';
import { EllipsisLoader } from '../loaders/EllipsisLoader';
import { getAvatarImage } from '../../utils/userUtil';
import './styles.scss';

const Breadcrumbs = observer(() => {
  // const matches = useMatches();
  // const crumbs = matches
  //   // first get rid of any matches that don't have handle and crumb
  //   .filter((match) => Boolean(match.handle?.crumb))
  //   // now map them into an array of elements, passing the loader
  //   // data to each one
  //   .map((match) => match.handle.crumb(match.data));
  // return (
  //   <>
  //   {crumbs.map((crumb, index) => (
  //     <div key={index}>{crumb}</div>
  //   ))}
  //   </>
  // );
  
  return (
    <div className="breadcrumbs">
      <div className='item'><a>Home</a></div>
      <div className='item'><a>Custom Leaderboards</a></div>
      <div className='item'><a>Flinstone's Rock Aiming</a></div>
      <div className='item'><a>Edit</a></div>
    </div>
  );
});

export const Header = observer(() => {

  const { toggleSidebar, i18n } = useRoot();
  const {
    currentUser,
    isUserLoading,
    loadUserInfo,
    logout,
    showLoginModal,
    setShowModal,
    changeModalVisible,
    User
  } = useUser();

  
  const { avatarImage, avatarType } = getAvatarImage(currentUser)

  useEffect(() => {
    loadUserInfo()
  }, []);

  const navigate = useNavigate();

  function handleLogin(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowModal({ login: true });
  }

  function handleRegister(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowModal({ register: true });
  }

  return (
  <div className="neo-header">
    <NavLink to="/kovaaks/main" className='logo-container'>
      <img className='logo-img' src={logoImg} />
      <img className='logo-text' src={logoText} />
    </NavLink>
    <div className="sidebar-control" onClick={toggleSidebar}>
      <img src={iconCollapse} alt="" />
    </div>
    {/* BREADCRUMBS */}
    <Breadcrumbs />
    <div className="controls">
      <Search
        query={User.searchUsers}
        dropdown
        persistent
        ItemComponent={UserItem}
        placeholder="Search players..."
        onCommit={e => {
          navigate('/kovaaks/profile?username=' + encodeURIComponent(e.value))
        }}
      />
      <Dropdown
        options={i18n.localeOptions.map(i => ({ ...i, value: i.flag }))}
        selectedItem={{ value: i18n.localeOptions.find(i => i.value === i18n.locale).flag }}
        onChange={e => {
          i18n.changeLocale(i18n.localeOptions.find(i => i.flag === e.value).value)
        }}
        ItemComponent={CountryItem}
        narrow
        style={{ maxWidth: '48px', minWidth: '48px', margin: '0 2px 0 8px' }}
        hideHandle
      />
      {isUserLoading && <EllipsisLoader style={{ position: 'absolute', right: 0 }} />}
      {currentUser?.webapp?.username
        ? <>
          {(currentUser && avatarImage) ?
            <img
              src={avatarImage}
              className={`global-header-user-avatar ${avatarType}-profile`}
              alt=""
            /> :
            <UserIcon
              size={30}
              style={{ padding: 8, color: '#ed4800', filter: 'drop-shadow(0px 0px 4px #00000080)' }}
            />
          }
          <div className="global-header-user-label">
            {currentUser?.webapp?.username
              ? <>{currentUser?.webapp?.username} { currentUser.kovaaksPlusActive && <KovaaksPlusIcon size="14" /> } </>
              : i18n.getString('common.login')
            }
            </div>
         </>
        :
        <>
          <button className='login' onClick={handleLogin}>
            Log in
          </button>
          <button className='sign-up' onClick={handleRegister}>
            Sign up
          </button>
        </>
      }
    </div>
    {showLoginModal && <LoginModal onClose={() => {
      setShowModal(false)
    }} />}
  </div> 
  )
});
