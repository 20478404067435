import React, { useEffect, useState, useRef, forwardRef } from 'react';
import styled from 'styled-components/macro'
import countryList from 'country-list'
import { WEBAPP_BACKEND_URL, KOVAAKS_PUBLIC_BUCKET_DOMAIN } from '../../constants';

const CountryItemContainer = styled.li`
  display: flex;
  align-items: center;

  &.selected {
    filter: brightness(1.2);
  }
  .name {
    padding-left: 8px;
  }
`

const FlagContainer = styled.div`
  max-width: 28px;
  max-height: 21px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  height: min-content;
  width: min-content;
  object-fit: contain;
  object-position: center;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: overlay;
    display: block;
    top: 0px;
    left: 0px;
    background-image: linear-gradient(45deg, rgba(0,0,0,0.20) 0%, rgba(39,39,39,0.22) 11%, rgba(255,255,255,0.30) 27%, rgba(0,0,0,0.24) 41%, rgba(0,0,0,0.55) 52%, rgba(255,255,255,0.26) 63%, rgba(0,0,0,0.27) 74%, rgba(255,255,255,0.30) 100%);
  }

  img {
    object-fit: contain;
    object-position: center;
    max-width: 20px;
    max-height: 15px;
    width: 20px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.10);
  }
`


export const Flag = props => {
  const { code, style, ...rest } = props
  if (!code) return null
  const flagPath = `${KOVAAKS_PUBLIC_BUCKET_DOMAIN}/country_flags/${code.toUpperCase()}.svg`
  
  const countryName = countryList.getName(code.toUpperCase())
  return (
    <FlagContainer style={style} {...rest} title={countryName}>
      <img className="flag" src={flagPath} alt={`${countryName}`} />
      <div className="overlay"></div>
    </FlagContainer>
  )
}

export const CountryItem = forwardRef((props, ref) => {
  const { item, index, ...rest } = props
  if (!item) return null
  return (
    <CountryItemContainer
      data-index={index}
      key={item.value}
      {...rest}
    >
      <Flag code={item.value} />
      <div className="name">{item.name}</div>
    </CountryItemContainer>
  )
})

