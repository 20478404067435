import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components/macro'
import { Observer, observer } from "mobx-react-lite";
import ReactDOM from 'react-dom';
import {
  useNavigate,
  useLocation,
  useSearchParams,
  NavLink
} from 'react-router-dom';
import './styles.scss';
import iconlogo from '../../assets/images/KovaaKsLogoResizedOptimized.png';
import { LoginModal } from '../login-modal';
import { useUser } from '../../core';
import { useRoot } from '../../core/rootStore'
import { EllipsisLoader } from '../loaders/EllipsisLoader';
import { getAvatarImage } from '../../utils/userUtil';
import { useResponsive } from '../../hooks/useResponsive'
import { HamburgerMenuIcon } from '../icons/HamburgerMenuIcon'
import { UserIcon } from '../icons/UserIcon'
import { Search } from '../Search'
import { SearchIcon } from '../icons/SearchIcon'
import { KovaaksPlusIcon } from '../icons/KovaaksPlusIcon';
import { UserItem } from '../list-items/UserItem'
import { CountryItem } from '../list-items/CountryItem';
import { enablePlaylists, enableAcademy, enablePromo, enableBenchmarkTracker } from '../../features';
import { Dropdown } from '../Dropdown';

const modalPortal = document.getElementById('header-modal');

const showTeamView = false

const HeaderContainer = styled.div`
  position: relative;
  z-index: 100;

  .search-tray {
    display: flex;
    padding: 8px;
    margin: 0px 8px;
    position: absolute;
    bottom: -54px;
    width: calc(100% - 16px);
    height: 48px;
    border-radius: 8px;
    background: #2f2934;
    box-shadow: 0px 0px 8px 0px #00000080;
    z-index: -1;
  }
`

const SearchBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const UserDropdownContent = (props) => {
  const { currentUser, dropdownAnchorState, showDropDown, setShowDropdown, setShowModal } = props
  const { gotoProfile, gotoAdmin, gotoManage, gotoLogout, goToLinkDiscord } = props
  const { i18n } = useRoot()
  return (
    <>
      <div
        className={`dropdown-backdrop ${showDropDown ? 'show' : ''}`}
        onClick={e => {
          e.stopPropagation()
          setShowDropdown(false);
          setShowModal(false)
        }}></div>
      <div style={{ right: 4, top: dropdownAnchorState.top }} className={showDropDown ? 'dropdown-content-show' : 'dropdown-content-hide'}>
        <div className="dropdown-overlay"></div>
        <a onClick={e => { e.stopPropagation(); gotoProfile(); }}>{ i18n.getString('header.my_profile') }</a>
        <a onClick={e => { e.stopPropagation(); gotoManage() }}>{ i18n.getString('header.manage_profile') }</a>
        {!currentUser?.webapp?.discord_linked && <a onClick={e => { e.stopPropagation(); goToLinkDiscord() }}>Link Discord</a>}
        {currentUser?.webapp?.roles?.admin && <a onClick={e => { e.stopPropagation(); gotoAdmin() }}>Admin</a>}
        <a onClick={e => { e.stopPropagation(); gotoLogout() }}>{ i18n.getString('common.logout') }</a>
      </div>
    </>
  )
}

const MobileDropdownContent = props => {
  const { currentUser, dropdownAnchorState, showDropDown, setShowDropdown, setShowModal } = props
  const { gotoProfile, gotoAdmin, gotoManage, gotoLogout } = props
  const { i18n } = useRoot()
  const navigate = useNavigate()

  const gotoLink = (url) => {
    setShowDropdown(false);
    setShowModal(false)
    navigate(url)
  }

  const openExternal = url => {
    setShowDropdown(false);
    setShowModal(false)
    window.open(url, '_blank')
  }

  const hasKPlus = currentUser?.kovaaksPlus.active

  return (
    <>
      <div
        className={`dropdown-backdrop ${showDropDown ? 'show' : ''}`}
        onClick={e => {
          e.stopPropagation()
          setShowDropdown(false);
          setShowModal(false)
        }}>
      </div>
      <div style={{ right: 4, top: dropdownAnchorState.top }} className={showDropDown ? 'dropdown-content-show' : 'dropdown-content-hide'}>
        <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks') }}>{ i18n.getString('header.home') }</a>
        <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/scenarios') }}>{ i18n.getString('common.scenarios') }</a>
        {enablePlaylists ? <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/playlists') }}>{ i18n.getString('common.playlists') }</a> : null}
        <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/sens-converter') }}>{ i18n.getString('header.sensitivity_converter') }</a>
        <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/leaderboards') }}>{ i18n.getString('header.global_leaderboards') }</a>
        {enableBenchmarkTracker ? <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/benchmark-tracker') }}>Benchmark Tracker</a> : null}
        {enablePromo ? <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/plus') }}>KovaaK's Supporter</a> : null}
        {(enableAcademy && hasKPlus) ? <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/academy') }}>Academy</a> : null}
        {/* <a onClick={e => { e.stopPropagation(); openExternal('https://league.themeta.gg/') }}>KovaaKs League</a> */}
        <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/FAQ') }}>{ i18n.getString('header.FAQ') }</a>
        <a onClick={e => { e.stopPropagation(); gotoLink('/kovaaks/YearRecap') }}>{ i18n.getString('header.year_recap') }</a>
        <a onClick={e => { e.stopPropagation(); openExternal('https://kovaaks.creator-spring.com/') }}>{ i18n.getString('header.merch') }</a>
        <a onClick={e => { e.stopPropagation(); gotoLogout() }}>{ i18n.getString('common.logout') }</a>
      </div>
    </>
  )
}

export const Header = observer(() => {
  const rlocation = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [adminHeader, setAdminHeader] = useState(false);
  const { nav, i18n } = useRoot()

  const anchroRef = useRef()
  const [dropdownAnchorState, setDropdownAnchor] = useState({ x: 0, y: 0 })
  const {
    currentUser,
    isUserLoading,
    loadUserInfo,
    logout,
    showLoginModal,
    setShowModal,
    changeModalVisible,
    User
  } = useUser();

  const breakpoint = useResponsive()

  const dropdownAnchorCallback = useCallback(el => {
    if (el) {
      anchroRef.current = el
      const bounds = el.getBoundingClientRect()
      setDropdownAnchor({ right: bounds.right, top: bounds.bottom })
    }
  }, [])


  const [showDropDown, setShowDropdown] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false)

  const handleResize = useCallback(() => {
    if (anchroRef.current) {
      const bounds = anchroRef.current.getBoundingClientRect()
      setDropdownAnchor({ right: bounds.right, top: bounds.bottom })
    }
  }, [])

  React.useEffect(() => {
    if (!currentUser && localStorage.jwtToken) loadUserInfo()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const handleMenuClick = () => {
    setShowMobileDropdown(true)
    setShowDropdown(false)
  }

  const handleProfileClick = () => {
    if (!currentUser) {
      // if not logged in, pop up sign in modal
      setShowModal({ login: true })
    } else {
      // logged in, show dropdown
      setShowDropdown(true)
      setShowMobileDropdown(false)
    }
  }

  const gotoProfile = () => {
    setShowDropdown(false)
    navigate('/kovaaks/profile?username=' + encodeURIComponent(currentUser.webapp.username))
  }

  const gotoManage = () => {
    setShowDropdown(false)
    navigate('/kovaaks/profile/manage?username=' + encodeURIComponent(currentUser.webapp.username));
  };

  const gotoAdmin = () => {
    setShowDropdown(false)
    navigate('/admin')
  }

  const gotoLogout = () => {
    setShowDropdown(false)
    logout();
    localStorage.removeItem('jwt');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('Username');
    navigate('/kovaaks');
  };

  const goToLinkDiscord = () => {
    setShowDropdown(false)
    navigate('/kovaaks/link-discord');
  }

  const handleClickItem = (e) => {
    if (currentUser == null) {
      e.preventDefault();
    }
  };

  // const showTeamView = currentUser && (currentUser?.webapp?.roles?.admin || currentUser?.webapp?.roles?.coach)

  const { avatarImage, avatarType } = getAvatarImage(currentUser)

  const hasKPlus = currentUser?.kovaaksPlus.active

  return (
    <>
      <SearchBackdrop />
      <HeaderContainer className="global-header">
        <a className="global-header-logo" href="/">
          <img src={iconlogo} width="520" height="88" alt="logo" />
          <div className="logo-beta">BETA</div>
        </a>
        <div className="global-header-content">
          {!adminHeader && (
            <>
              <NavLink to="/kovaaks" end>{ i18n.getString('header.home') }</NavLink>
              {(enableAcademy && hasKPlus) ?
              <NavLink to="/kovaaks/academy">
                Academy
              </NavLink> : null}
              {enablePromo ?
              <NavLink to="/kovaaks/plus">
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  KovaaK's Supporter
                  <KovaaksPlusIcon />
                </div>
              </NavLink> : null}
              {showTeamView ? <a
                href="https://league.themeta.gg/"
                target="_blank"
              >
                KovaaK's League
              </a> : null}
              {showTeamView &&
              <NavLink
                onClick={handleClickItem}
                to="/teamview/main"
              >
                TeamView
              </NavLink>}
              <a href="/kovaaks/FAQ">
                { i18n.getString('header.FAQ') }
              </a>
              <a href="/kovaaks/YearRecap">
                { i18n.getString('header.year_recap') }
              </a>
              <a href=" https://kovaaks.creator-spring.com/" target="_blank">
                { i18n.getString('header.merch') }
              </a>
            </>
          )}
        </div>
        <div className="global-menu">
          <Observer>
          {() => (breakpoint !== 'xs') ?
            <Search
              persistent={breakpoint !== 'xs' ? true : false}
              dropdown
              query={User.searchUsers}
              placeholder={i18n.getString('placeholders.search_players')}
              ItemComponent={UserItem}
              style={{ marginRight: 8 }}
              onCommit={e => {
                if (!e.value) return
                navigate('/kovaaks/profile?username=' + encodeURIComponent(e.value))
              }}
            /> :
            <SearchIcon
              style={{ color: '#625f68', height: '100%', width: 60, filter: nav.showSearch ? 'brightness(1.3)' : '' }}
              onMouseDown={e => {
                // prevent click bubbling and losing focus on search
                e.preventDefault()
                e.stopPropagation()
                // this button opens the <Search /> in App.jsx
                setShowMobileDropdown(false)
                setShowDropdown(false)
                nav.togglePlayerSearch()
              }}
            />
          }
          </Observer>
          <Dropdown
            options={i18n.localeOptions.map(i => ({ ...i, value: i.flag }))}
            selectedItem={{ value: i18n.localeOptions.find(i => i.value === i18n.locale).flag }}
            onChange={e => {
              i18n.changeLocale(i18n.localeOptions.find(i => i.flag === e.value).value)
            }}
            ItemComponent={CountryItem}
            narrow
            style={{ maxWidth: '48px', minWidth: '48px', margin: '0 2px 0 8px' }}
            hideHandle
          />
          <div className="global-header-user" onClick={breakpoint === 'xs' ? null : () => handleProfileClick()}>
            {isUserLoading && <EllipsisLoader style={{ position: 'absolute', right: 0 }} />}
            <button
              key={(currentUser?.webapp?.username) ? currentUser?.webapp?.username : 'login'}
              style={{ opacity: isUserLoading ? 0 : 1, visibility: isUserLoading ? 'hidden' : 'visible' }}
              className="global-header-user-username"
              ref={dropdownAnchorCallback}
              onClick={e => { e.preventDefault();  handleProfileClick() }}
            >
              {(currentUser && avatarImage) ?
                <img
                  src={avatarImage}
                  className={`global-header-user-avatar ${avatarType}-profile`}
                  alt=""
                /> :
                <UserIcon
                  size={30}
                  style={{ padding: 8, color: '#ed4800', filter: 'drop-shadow(0px 0px 4px #00000080)' }}
                />
              }
              <Observer>
              {() => breakpoint !== 'xs' ?
                <div className="global-header-user-label">
                {currentUser?.webapp?.username
                  ? <>{currentUser?.webapp?.username} { currentUser.kovaaksPlusActive && <KovaaksPlusIcon size="14" /> } </>
                  : i18n.getString('common.login')
                }
                </div> : null
              }
              </Observer>
              <div className="dropdown">
                {breakpoint !== 'xs' ?  <div className="global-header-user-arrow global-header-user-down"/> : null }
                {showDropDown ? ReactDOM.createPortal((
                  <UserDropdownContent
                    currentUser={currentUser}
                    dropdownAnchorState={dropdownAnchorState}
                    showDropDown={showDropDown}
                    setShowDropdown={setShowDropdown}
                    setShowModal={setShowModal}
                    gotoProfile={gotoProfile}
                    gotoManage={gotoManage}
                    gotoAdmin={gotoAdmin}
                    gotoLogout={gotoLogout}
                    goToLinkDiscord={goToLinkDiscord}
                  />)
                , modalPortal) : null}
                {(showMobileDropdown && breakpoint === 'xs') ? ReactDOM.createPortal(
                  (<MobileDropdownContent
                    currentUser={currentUser}
                    dropdownAnchorState={dropdownAnchorState}
                    showDropDown={showMobileDropdown}
                    setShowDropdown={setShowMobileDropdown}
                    setShowModal={setShowModal}
                    gotoProfile={gotoProfile}
                    gotoManage={gotoManage}
                    gotoAdmin={gotoAdmin}
                    gotoLogout={gotoLogout}
                  />), modalPortal) : null}
              </div>
            </button>
          </div>
          <div className="global-header-user">
            {!isUserLoading && breakpoint === 'xs' ? (
              <HamburgerMenuIcon
                size={28}
                style={{ padding: 16, color: '#ed4800', filter: 'drop-shadow(0px 0px 4px #00000080)' }}
                onClick={() => handleMenuClick()}
              />
            ) : null}
          </div>
        </div>
      </HeaderContainer>
      {showLoginModal && <LoginModal onClose={() => {
        setShowModal(false)
      }} />}
    </>
  );
})
