import React, { useState, useEffect, Suspense, lazy } from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload'
import { Routes, Route, useLocation, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { setAuthToken } from '../../utils';
import { Loader } from '../../components/Loader';
import { enableBenchmarkTracker, enablePlaylists, enableAcademy, enableCreator, enablePromo, enableGlobalLeaderboard } from '../../features';

const KovaaKsPlusPromo = lazyWithPreload(() => import('../../components/promo/KovaaKsPlusPromo'))
const Playlists = lazyWithPreload(() => import('../../components/playlists/Playlists'))
const Merch = lazyWithPreload(() => import('../../components/Merch'))
// const Scenarios = lazyWithPreload(() => import('../../components/scenarios/Scenarios'))
const LeaderBoards = lazyWithPreload(() => import('../../components/leaderboards/Leaderboards'))
const GlobalLeaderBoards = lazyWithPreload(() => import('../../components/global-leaderboards/GlobalLeaderboards'))
const HomePage = lazyWithPreload(() => import('./HomePage'))
const Profile = lazyWithPreload(() => import('../../components/profile/Profile'))
const PrivacyPolicy = lazyWithPreload(() => import('../../components/PrivacyPolicy'))
const TermsOfService = lazyWithPreload(() => import('../../components/TermsOfService'))
const FAQ = lazyWithPreload(() => import('../../components/FAQ'))
const SensConverter = lazyWithPreload(() => import('../../components/sens-converter/SensConverter'))
const Academy = lazyWithPreload(() => import('../../components/academy/Academy'))
const AcademyVideo = lazyWithPreload(() => import('../../components/academy/AcademyVideo'))
const BenchmarkCreator = lazyWithPreload(() => import('../../components/benchmark-creator/index'))
const LinkDiscord = lazyWithPreload(() => import('../../components/link-discord'))
const BenchmarkTracker = lazyWithPreload(() => import('../../components/benchmark-tracker/index'))
const YearRecap = lazyWithPreload(() => import('../../components/YearRecap'))
const PlayerRecap = lazyWithPreload(() => import('../../components/PlayerRecap'))

import { useUser } from '../../core';
import { useResponsive } from '../../hooks/useResponsive'
import './styles.scss';

const SteamConnected = () => {
  const { setShowModal } = useUser()

  useEffect(() => {
    const username = localStorage.getItem('Username')
    const token = localStorage.getItem('jwtToken')
    if (username && token) return
    setShowModal({ register: true })
  }, [])

  return (
    <HomePage />
  )
}

export default function KovaaksScene() {
	const location = useLocation();
	const [token, setToken] = useState(null);
	const [searchParams] = useSearchParams();

  let breakpoint = useResponsive()
  const { currentUser } = useUser()

	useEffect(() => {
		const token = searchParams.get('token');
		if(typeof token != 'undefined' && token && location.pathname.includes('/kovaaks/main')) {
			const newToken = `Bearer ${token}`;
			localStorage.setItem('jwtToken', newToken);
			setAuthToken(newToken);
			window.location.href = '/';
		}
		setToken(token);

    // lazy imports to get faster first render
    // we preload after the first render because:
    // 1. it can crash the page if they didn't load a chunk yet and we deploy in the meantime (old chunks are deleted)
    // 2. the site is faster to respond
    setTimeout(() => {
      Playlists.preload()
      // Scenarios.preload()
      LeaderBoards.preload()
      HomePage.preload()
      Profile.preload()
      PrivacyPolicy.preload()
      TermsOfService.preload()
      FAQ.preload()
      BenchmarkCreator.preload()
      KovaaKsPlusPromo.preload()
      Academy.preload()
      AcademyVideo.preload()
      YearRecap.preload()
      // PlayerRecap.preload()
    }, 10000)

	}, [searchParams]);

	return (
    <div className={`content-wrapper`}>
      <Suspense fallback={<div className={`kovaaks-container-block`}><Loader /></div>}>
        <Routes>
          <Route path="main" element={<HomePage />} />
          <Route path={`scenarios`} element={<LeaderBoards />} />
          {enablePromo ? <Route path={`plus`} element={<KovaaKsPlusPromo />} /> : null}
          <Route path={`profile/*`} element={<Profile />} />
          <Route path="steam-connected" element={<SteamConnected />} />
          <Route path="sens-converter" element={<SensConverter />} />
          <Route path="cm360-converter" element={<SensConverter mode="cm360" />} />
          {enableAcademy ? <Route path="academy" element={<Academy />} /> : null}
          {enableAcademy ? <Route path="academy/video" element={<AcademyVideo />} /> : null}
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="FAQ" element={<FAQ />} />
          <Route path={`playlists`} element={<Playlists />} />
          <Route path={`merch`} element={<Merch />} />
          {enableGlobalLeaderboard ? <Route path="leaderboards" element={<GlobalLeaderBoards />} /> : null}
          {enableCreator ? <Route path={`benchmark-creator`} element={<BenchmarkCreator />} /> : null}
          <Route path='link-discord' element={<LinkDiscord />} />
          {enableBenchmarkTracker ? <Route path='benchmark-tracker' element={<BenchmarkTracker />} /> : null }
          <Route path="YearRecap" element={<YearRecap />} />
          {/* <Route path="PlayerRecap" element={<PlayerRecap />} /> */}
        </Routes>
      </Suspense>
    </div>
	);
}