import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRoot } from '../../core/rootStore'
import { NavLink } from 'react-router-dom';
import './styles.scss';

import iconHome from '../../assets/images/neo/home.svg';
import iconScenarios from '../../assets/images/neo/scenarios.svg';
import iconGlobal from '../../assets/images/neo/global.svg';
import iconCustom from '../../assets/images/neo/custom.svg';
import iconPlaylists from '../../assets/images/neo/playlists.svg';
import iconTracker from '../../assets/images/neo/tracker.svg';
import iconCreator from '../../assets/images/neo/creator-tools.svg';
import iconFaq from '../../assets/images/neo/faq.svg';
import iconSupporter from '../../assets/images/neo/supporter.svg';
import iconDiscord from '../../assets/images/neo/Discord.svg';
import iconTwitter from '../../assets/images/neo/twitter.svg';
import iconInstagram from '../../assets/images/neo/Instagram.svg';
import iconYoutube from '../../assets/images/neo/YouTube.svg';
import iconFacebook from '../../assets/images/neo/Facebook.svg';
import iconReddit from '../../assets/images/neo/Reddit.svg';

export const Sidebar = observer(() => {
  const root = useRoot();
  return (
    <div className={`sidebar-container ${root.sidebarClosed ? 'collapsed' : ''}`}>
      <NavLink
        alt="Home"
        aria-label="Home"
        to="/kovaaks/main"
        className='nav-item'
      >
        <img src={iconHome} alt="" />
        <span>Home</span>
      </NavLink>
      <hr />
      <label>
        Leaderboards
      </label>
      <NavLink
        alt="Scenarios"
        aria-label="Scenarios"
        to="/kovaaks/scenarios"
        className='nav-item'
      >
        <img src={iconScenarios} alt="" />
        <span>Scenarios</span>
      </NavLink>
      <NavLink
        alt="Global"
        aria-label="Global"
        to="/kovaaks/leaderboards"
        className='nav-item'
      >
        <img src={iconGlobal} alt="" />
        <span>Global</span>
      </NavLink>
      <a className='nav-item'>
        <img src={iconCustom} alt="" />
        <span>Custom</span>
      </a>
      <hr />
      <NavLink
        alt="Playlists"
        aria-label="Playlists"
        to="/kovaaks/playlists"
        className='nav-item'
      >
        <img src={iconPlaylists} alt="" />
        <span>Playlists</span>
      </NavLink>
      <NavLink
        alt="Benchmark Tracker"
        aria-label="Benchmark Tracker"
        to="/kovaaks/benchmark-tracker"
        className='nav-item'
      >
        <img src={iconTracker} alt="" />
        <span>Benchmark Tracker</span>
      </NavLink>
      <NavLink
        alt="Creator Tools"
        aria-label="Creator Tools"
        to="/kovaaks/benchmark-creator"
        className='nav-item'
      >
        <img src={iconCreator} alt="" />
        <span>Creator Tools</span>
      </NavLink>
      <NavLink
        alt="FAQ"
        aria-label="FAQ"
        to="/kovaaks/faq"
        className='nav-item'
      >
        <img src={iconFaq} alt="" />
        <span>FAQ</span>
      </NavLink>
      <hr />
      <NavLink
        alt="Sens Converter"
        aria-label="Sens Converter"
        to="/kovaaks/sens-converter"
        className='nav-item'
      >
        <img src={iconCreator} alt="" />
        <span>Sens Converter</span>
      </NavLink>
      <NavLink
        alt="KovaaK's Supporter"
        aria-label="KovaaK's Supporter"
        to="/kovaaks/plus"
        className='nav-item'
      >
        <img src={iconSupporter} alt="" />
        <span>KovaaK's Supporter</span>
      </NavLink>
      <a className='nav-item' href="https://kovaaks.creator-spring.com/" target="_blank">
        <img src={iconCreator} alt="" />
        <span>Merch</span>
      </a>
      <hr />
      <div className="socials">
        <a href="https://discord.gg/KovaaK" target="_blank">
          <img src={iconDiscord} alt="" />
        </a>
        <a href="https://twitter.com/KovaaKs" target="_blank">
          <img src={iconTwitter} alt="" />
        </a>
        <a href="https://www.instagram.com/kovaak2.0/" target="_blank">
          <img src={iconInstagram} alt="" />
        </a>
        <a href="https://www.youtube.com/c/TheMeta" target="_blank">
          <img src={iconYoutube} alt="" />
        </a>
        <a href="https://www.facebook.com/KovaaKs/" target="_blank">
          <img src={iconFacebook} alt="" />
        </a>
        <a href="https://www.reddit.com/r/FPSAimTrainer/" target="_blank">
          <img src={iconReddit} alt="" />
        </a>
      </div>
    </div>
  );
});
